// Variables
$primary-blue: #2F7AD3;
$white-opacity-10: rgba(255, 255, 255, 0.1);

// Container styles
.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  background-color: #1e1e1e;
  height: 48px;
  width: 100%;
  position: relative;
}

.marquee-content {
  height: 100%;
  align-items: center;
  
  &:hover {
    animation-play-state: paused;
  }
}

// Crypto item styles
.crypto-item {
  display: inline-flex;
  align-items: center;
  padding: 0 16px;
  gap: 8px;
  height: 100%;
  
  &:not(:last-child) {
    border-right: 1px solid $white-opacity-10;
  }
}

.crypto-icon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white-opacity-10;
  border-radius: 50%;

  .icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}

.crypto-text {
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  font-weight: 500;
  letter-spacing: -0.01em;
}